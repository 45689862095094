var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.updateHistoric
    ? _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "560", persistent: "" },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _vm.updateHistoric
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h5" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "font-size": "20px",
                                    "font-weight": "bold",
                                  },
                                  attrs: { cols: "11" },
                                },
                                [_vm._v(" Histórico de atualização ")]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.show = false
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.updateHistoric, function (u) {
                        return _c(
                          "v-card-text",
                          { key: u.id, staticClass: "h-title pt-5 pb-0" },
                          [
                            _c(
                              "div",
                              { staticClass: "row h-row mb-0 rounded" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "p-5" },
                                  [
                                    _c(
                                      "v-subheader",
                                      {
                                        staticClass: "pl-3 pt-5 pb-5",
                                        staticStyle: { "max-height": "24px" },
                                      },
                                      [_vm._v("Data/hora")]
                                    ),
                                    _c(
                                      "v-subheader",
                                      {
                                        staticClass: "pl-3 pt-0 pb-5",
                                        staticStyle: { "max-height": "24px" },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatHistoryDate(u.startedAt)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider", {
                                  staticClass: "mt-3 mb-3",
                                  attrs: { vertical: "" },
                                }),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c("v-subheader", {
                                      staticClass: "pl-3 pt-4 pb-3",
                                      staticStyle: { "max-height": "24px" },
                                    }),
                                    u.totalFailed === 0
                                      ? _c(
                                          "v-subheader",
                                          {
                                            staticClass:
                                              "pl-3 pt-0 pb-5 historic-success",
                                            staticStyle: {
                                              "max-height": "24px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Atualização realizada com sucesso"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _c(
                        "v-card-text",
                        { staticClass: "h-title pt-5 h-footer" },
                        [
                          _c("strong", [
                            _vm._v(
                              "Você visualizou o histórico dos últimos 7 dias."
                            ),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Para ter acesso as atualizações anteriores entre em contato com a Agnes."
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { id: "expt-btn" },
                              on: {
                                click: function ($event) {
                                  return _vm.exportar()
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    width: "1px !important",
                                    height: "1px !important",
                                    "margin-left": "-9px",
                                  },
                                },
                                [_vm._v("mdi-download-outline")]
                              ),
                              _vm._v("    Exportar"),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { id: "btnClose", color: "success" },
                              on: {
                                click: function ($event) {
                                  _vm.show = false
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }