<template>
  <v-row justify="center" v-if="updateHistoric">
    <v-dialog v-model="show" max-width="560" persistent>
      <v-card v-if="updateHistoric">
        <v-card-title class="text-h5">
          <v-row>
            <v-col cols="11" style="font-size: 20px; font-weight: bold"> Histórico de atualização </v-col>
            <v-col cols="1">
              <v-btn icon @click="show = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="h-title pt-5 pb-0" v-for="u in updateHistoric" v-bind:key="u.id">
          <div class="row h-row mb-0 rounded">
            <div class="p-5">
              <v-subheader style="max-height: 24px" class="pl-3 pt-5 pb-5">Data/hora</v-subheader>
              <v-subheader style="max-height: 24px" class="pl-3 pt-0 pb-5"
                ><strong>{{ formatHistoryDate(u.startedAt) }}</strong></v-subheader
              >
            </div>
            <v-divider vertical class="mt-3 mb-3"></v-divider>
            <div class="">
              <v-subheader style="max-height: 24px" class="pl-3 pt-4 pb-3"> </v-subheader>
              <v-subheader style="max-height: 24px" class="pl-3 pt-0 pb-5 historic-success" v-if="u.totalFailed === 0"
                >Atualização realizada com sucesso</v-subheader
              >
            </div>
          </div>
        </v-card-text>

        <v-card-text class="h-title pt-5 h-footer">
          <strong>Você visualizou o histórico dos últimos 7 dias.</strong>
          <p class="mb-0">Para ter acesso as atualizações anteriores entre em contato com a Agnes.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn id="expt-btn" @click="exportar()">
            <v-icon style="width: 1px !important; height: 1px !important; margin-left: -9px;">mdi-download-outline</v-icon
            >&nbsp&nbsp&nbsp&nbspExportar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn id="btnClose" color="success" @click="show = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  <script>
import IntegraContadorService from '@/services/integraContadorService.js';
import { eventBus } from '../../../../main';
export default {
  name: 'ModalHistoryUpdate',
  props: ['updateHistoric'],

  data() {
    return {
      show: false,
    };
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },
    open() {
      this.show = true;
    },
    async exportar() {
      let response = await IntegraContadorService.processes.downloadReport();
      if (response) this.loading3 = false;
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      link.download = `Atualizações de Mensagens do Ecac - Extraído em ${date.toLocaleDateString('pt-br')}.xlsx`;
      link.click();
      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
      this.close();
    },
    close() {
      this.show = false;
    },
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
    formatHistoryDate(historyDate) {
      historyDate = historyDate.replace(/T/, '-').replace(/\.\d+/, '');
      const [year, month, day, time] = historyDate.split('-');
      return `${day}/${month}/${year} ${time}`;
    },
  },
};
</script>
  <style>
.bg-sub-title {
  background-color: #f9fafb;
}

.content-empty {
  background-color: #e7e7fa;
  color: #2a1c4f;
}

.content-empty {
  background-color: #fff;
}
.h-title {
  background-color: #e7e7fa;
}
.h-row {
  background-color: #fff;
}
.h-line {
  color: rgba(0, 0, 0, 0.6);
}
.h-footer {
  text-align: center;
  color: var(--v-primary-base);
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.historic-success {
  color: #288569 !important;
}
#expt-btn {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 2px solid primary !important;
  background-color: #fff !important;
  color: var(--v-primary-base);
  width: 26%;
}

#expt-btn :hover {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  width: 138%;
  height: 240%;
  border-radius: 3px;
}

#btnClose {
  height: 48px !important;
}

#btnClose :hover{
  background-color: #e3ffee !important;
  width: 168%;
  height: 200%;
  color: var(--v-primary-base);
  border-radius: 3px;
}
</style>